import React from "react";
import axios from "axios";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";
import Slider from "@material-ui/core/Slider";

import Lightbox from "react-image-lightbox";
import { toastr } from "react-redux-toastr";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import { apiAddress } from "../../../config/general";
import Freguesias from "../../../components/Filters/Freguesias";

import { setDetails, setElement, setParkByNumber, delPark } from "../actions";
import { getParkParcelNumber } from "../Selector";

import { DisableButton } from "./styled";

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
  }
`;

const InlineBlock = styled.div`
  && {
    display: inline-block;
    width: 44%;
    margin-right: 2%;
  }
`;
const ImageContainer = styled.div`
  && {
    text-align: left;
  }
`;

const Image = styled.div`
  && {
    width: 49%;
    height: 35vh;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 0.5em;
    background-size: cover;
    cursor: pointer;
  }
`;

const Input = styled((props) => <TextField {...props} />)`
  && {
    width: 100%;
    margin: 0.5em 0;
    display: inline-block;
    margin-right: 5%;
    text-align: left;
    textarea {
      height: auto;
    }
    input {
      width: 100%;
    }
    div {
      width: 100%;
    }

    input:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

const InputFull = styled(TextField)`
  && {
    width: 100%;
    margin: 0.5em 0;
    display: block;
    text-align: left;
    textarea {
      height: auto;
      width: 100%;
    }
    div {
      width: 100%;
    }
    input:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
  }
`;

class EditParkDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    const data = props.data || {};

    const about = {
      designacao: data.designacao || "",
      freguesia: data.freguesia || "",
      função: data.função || "",
      local: data.local || "",
      localidade: data.localidade || "",
      morada: data.morada || "",
      nome: data.nome || "",
      numero: data.numero || "",
      observaçõe: data.observaçõe || "",
    };

    const responsible = {
      assistênci: data.assistênci || "",
      gestor: data.gestor || "",
      prestador: data.prestador || "",
      ranking0: data.ranking0 || 0,
      bairro: data.bairro || 500,
      ranking1: data.ranking1 || 0,
      proximidade: data.proximidade || 2000,
      ranking2: data.ranking2 || 0,
      freguesias: data.freguesias,
    };

    const maintenance = {
      actuação: data.actuação || "",
      area_emac: data.area_emac || "",
      designacao: data.designacao || "",
      inicio_prestacao: data.inicio_prestacao || "",
      rega: data.rega || "",
      tipo: data.tipo || "",
    };

    this.state = {
      about: props.data.about || about,
      responsible: props.data.responsible || responsible,
      maintenance: props.data.maintenance || maintenance,
      isOpen: null,
      pid: props.data.numero || props.selected,
      elementId: null,
      photoIndex: 0,
      implementationDate: this.getDate(),
      files: props.files || props.data.files || [],
    };

    this.serverURL = apiAddress;
    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*, .pdf, .doc, .docx, .xls, .xlsx",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: this.state.pid, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.updateRanking2 = this.updateRanking2.bind(this);
    this.updateRanking0 = this.updateRanking0.bind(this);
    this.updateRanking1 = this.updateRanking1.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveElement = this.handleSaveElement.bind(this);
    this.handleSetPreferedPark = this.handleSetPreferedPark.bind(this);
    this.delete = this.delete.bind(this);
    this.updatedFreguesias = this.updatedFreguesias.bind(this);
  }

  componentDidUpdate() {
    if (this.props.status === "done") {
    }
  }

  getDate(date) {
    const now = date ? new Date(date) : new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({ files: files });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    console.log(file);
    // this.setState({ photos: [...this.state.photos, file.upload] });
  }

  handleChange(e, category) {
    if (category) {
      this.setState({
        [category]: {
          ...this.state[category],
          [e.target.name]: e.target.value,
        },
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  updatedFreguesias(e) {
    this.setState({
      responsible: {
        ...this.state.responsible,
        freguesias: e.target.value,
      },
    });
  }

  handleSetPreferedPark() {
    if (!this.props.selected) return;
    try {
      axios
        .get(`/api/v1/contacts/preferedUsersToPark/${this.props.selected}`)
        .then(function(response) {
          if (response.data.msg) {
            console.log(response.data);
            toastr.success("Sucesso", "Parque adicionado como preferencial");
          } else {
            toastr.error("Erro", "Erro ao adicionar parque como preferencial");
          }
        })
        .catch(function(error) {
          toastr.error("Erro", "Erro ao adicionar parque como preferencial");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  handleSaveElement() {
    const details = {
      responsible: this.state.responsible,
      about: this.state.about,
      maintenance: this.state.maintenance,
      files: this.state.files,
    };

    this.props.setDetails(details);
    this.props.setParkByNumber(this.props.selected || null);
  }

  totalRankings(newVal, ranking) {
    const quotas = this.state.responsible;
    let total = 0;
    if (ranking === "ranking0") {
      total = newVal + quotas.ranking2 + quotas.ranking1;
    } else if (ranking === "ranking2") {
      total = quotas.ranking0 + newVal + quotas.ranking1;
    } else if (ranking === "ranking1") {
      total = quotas.ranking0 + quotas.ranking2 + newVal;
    }
    if (total > this.props.parcels) {
      return this.state.responsible[ranking];
    }
    return newVal;
  }

  updateRanking0(type, e) {
    const newValue = this.totalRankings(e, "ranking0");
    this.setState({
      responsible: {
        ...this.state.responsible,
        ranking0: newValue,
      },
    });
  }
  updateRanking2(type, e) {
    const newValue = this.totalRankings(e, "ranking2");
    this.setState({
      responsible: {
        ...this.state.responsible,
        ranking2: newValue,
      },
    });
  }
  updateRanking1(type, e) {
    const newValue = this.totalRankings(e, "ranking1");
    this.setState({
      responsible: {
        ...this.state.responsible,
        ranking1: newValue,
      },
    });
  }

  renderPhotos(photos) {
    //create a new array with the photos
    let images = [];
    let files = [];

    photos.forEach((photo) => {
      if (photo.path.match(/(jpg|jpeg|png|gif)$/)) {
        images.push(photo);
      } else {
        files.push(photo);
      }
    });
    //create a new array with files

    return (
      <div>
        {files.length > 0 && (
          <>
            <SectionTitle>Ficheiros</SectionTitle>
            <ul style={{ display: "flex", flexWrap: "wrap" }}>
              {//show files
              files?.map((file, index) => {
                return (
                  <li key={index} style={{ margin: "1em" }}>
                    <a href={`${this.serverURL}${file.path}`} target="_blank">
                      {file.name}
                    </a>
                    <div
                      onClick={() => {
                        const files = this.state.files.filter(
                          (f) => f.path !== file.path
                        );
                        this.setState({ files });
                      }}
                      style={{
                        fontSize: "0.8em",
                        cursor: "pointer",
                        color: "red",
                      }}
                    >
                      ( Apagar )
                    </div>
                  </li>
                );
              })}{" "}
            </ul>
          </>
        )}
        <ImageContainer>
          {images &&
            images.map((photo, index) => (
              <>
                <Image
                  onClick={() =>
                    this.setState({
                      isOpen: true,
                      photoIndex: index,
                    })
                  }
                  key={index}
                  style={{
                    backgroundImage: `url('${this.serverURL}${photo.path}')`,
                  }}
                />
                <div
                  onClick={() => {
                    const files = this.state.files.filter(
                      (f) => f.path !== photo.path
                    );
                    this.setState({ files });
                  }}
                  style={{
                    fontSize: "0.8em",
                    cursor: "pointer",
                    color: "red",
                  }}
                >
                  ( Apagar )
                </div>
              </>
            ))}
        </ImageContainer>
      </div>
    );
  }

  checkIfQuotasAreNotUpdated(quotas) {
    const ranking0 = this.state.responsible.ranking0;
    const ranking1 = this.state.responsible.ranking1;
    const ranking2 = this.state.responsible.ranking2;
    const total = ranking0 + ranking1 + ranking2;
    if (total > this.props.parcels) {
      this.setState({
        responsible: {
          ...this.state.responsible,
          ranking0: 0,
          ranking1: 0,
          ranking2: 0,
        },
      });
    }
  }

  delete(pid) {
    const toastrConfirmOptions = {
      onOk: () => this.props.delPark(pid),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;
    const { photoIndex, isOpen } = this.state;
    const images = this.state.files;

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      removedfile: this.removedfile,
      success: this.callback,
    };

    this.checkIfQuotasAreNotUpdated();

    return (
      <div>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Informação sobre o Parque</SectionTitle>
            <OptionWrap>
              {this.state.about &&
                Object.keys(this.state.about).map((ele, index) => {
                  if (ele === "observaçõe") {
                    return (
                      <InputFull
                        key={index}
                        multiline
                        label="Observações"
                        onChange={(e) => this.handleChange(e, "about")}
                        defaultValue={this.state.about[ele]}
                        name="observaçõe"
                        input={{}}
                        type="multiline"
                        meta={{ invalid: false }}
                        rows="8"
                        disabled={this.props.view}
                      />
                    );
                  }
                  return (
                    <InlineBlock key={index}>
                      <Input
                        label={ele}
                        onChange={(e) => this.handleChange(e, "about")}
                        defaultValue={this.state.about[ele]}
                        name={ele}
                        input={{}}
                        meta={{ invalid: false }}
                        type="text"
                        disabled={this.props.view}
                      />
                    </InlineBlock>
                  );
                })}
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>
              Quotas ( {this.props.parcels} parcelas )
            </SectionTitle>
            <OptionWrap>
              <p>
                Bairro -{" "}
                <small>
                  {(
                    (this.state.responsible.ranking0 * 100) /
                      this.props.parcels || 0
                  ).toFixed(1)}
                  %
                </small>
              </p>
              <Slider
                // defaultValue={50}
                value={this.state.responsible.ranking0 || 0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                disabled={this.props.view}
                step={1}
                color={"primary"}
                name={"ranking0"}
                onChange={this.updateRanking0}
                marks
                min={0}
                max={this.props.parcels}
              />
              <p>
                Proximidade -{" "}
                <small>
                  {(
                    (this.state.responsible.ranking1 * 100) /
                      this.props.parcels || 0
                  ).toFixed(1)}
                  %
                </small>
              </p>
              <Slider
                value={this.state.responsible.ranking1 || 0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                color={"primary"}
                name={"ranking1"}
                onChange={this.updateRanking1}
                disabled={this.props.view}
                step={1}
                marks
                min={0}
                max={this.props.parcels}
              />
              <p>
                Freguesia -{" "}
                <small>
                  {(
                    (this.state.responsible.ranking2 * 100) /
                      this.props.parcels || 0
                  ).toFixed(1)}
                  %
                </small>
              </p>
              <Slider
                value={this.state.responsible.ranking2 || 0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                color={"primary"}
                name={"ranking2"}
                onChange={this.updateRanking2}
                disabled={this.props.view}
                step={1}
                marks
                min={0}
                max={this.props.parcels}
              />
              <Freguesias
                multiple={true}
                view={this.props.view}
                freguesia={this.state.responsible.freguesias || []}
                handleFreguesiaChange={this.updatedFreguesias}
              />
            </OptionWrap>
            <br />
            <NextButton
              color="primary"
              onClick={() => this.handleSetPreferedPark()}
            >
              Adicionar como preferencial
              {/* <MenuIcon className={`icon-check icons`} /> */}
            </NextButton>
          </Col>
        </Row>
        <Row>
          <Col xs={8} md={8}>
            <SectionTitle>Manutenção</SectionTitle>
            <OptionWrap>
              {this.state.maintenance &&
                Object.keys(this.state.maintenance).map((ele, index) => {
                  return (
                    <InlineBlock key={index}>
                      <Input
                        label={ele}
                        onChange={(e) => this.handleChange(e, "maintenance")}
                        defaultValue={this.state.maintenance[ele]}
                        name={ele}
                        input={{}}
                        meta={{ invalid: false }}
                        type="text"
                        disabled={this.props.view}
                      />
                    </InlineBlock>
                  );
                })}
            </OptionWrap>
          </Col>
          <Col xs={4} md={4}>
            <SectionTitle>Geral</SectionTitle>
            <OptionWrap>
              {this.state.responsible &&
                Object.keys(this.state.responsible).map((ele, index) => {
                  if (
                    ele === "ranking0" ||
                    ele === "ranking2" ||
                    ele === "ranking1"
                  )
                    return null;
                  return (
                    <InputFull
                      key={index}
                      label={ele}
                      onChange={(e) => this.handleChange(e, "responsible")}
                      defaultValue={this.state.responsible[ele]}
                      name={ele}
                      input={{}}
                      meta={{ invalid: false }}
                      type="text"
                      disabled={this.props.view}
                    />
                  );
                })}
            </OptionWrap>
          </Col>
          {images && images.length > 0 && (
            <Row>
              <Col xs={12} md={12}>
                {this.renderPhotos(images)}
              </Col>
            </Row>
          )}
          {!this.props.view && (
            <Col xs={12} md={12}>
              <SectionTitle>Opções</SectionTitle>
              <OptionWrap>
                <Files
                  config={config}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                />
                {this.props.status !== "getting" &&
                  this.props.status !== "saving" && (
                    <NextButton
                      color="primary"
                      onClick={() => this.handleSaveElement()}
                    >
                      Submeter
                      <MenuIcon className={`icon-check icons`} />
                    </NextButton>
                  )}
                {this.props.isAdmin && (
                  <DisableButton onClick={() => this.delete(this.state.pid)}>
                    <MenuIcon className={`icon-close icons`} /> Destivar
                  </DisableButton>
                )}
              </OptionWrap>
            </Col>
          )}
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      park: state.georeference.park,
      elements: state.georeference.elements,
      location: state.parklist.location,

      parcels: getParkParcelNumber(state),
      selected: state.app.search.selected,
      token: state.user.data.token,
      status: state.georeference.status,
      isAdmin: state.user.data.admin,
      elementTypesList: state.georeference.elementTypes,
      vegetationTypes: state.georeference.vegetationTypes,
    };
  },
  { setDetails, setElement, setParkByNumber, delPark }
)(EditParkDetails);
